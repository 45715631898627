<template>
  <div>
    <shop-head currentPageName="ORDER"></shop-head>
    <!-- 面包屑 -->
    <breadcrumb pageName="Order"></breadcrumb>
    <div class="container table-box">
      <el-table
        :data="orderList"
        border
        :span-method="arraySpanMethod"
        empty-text="no data"
        style="width: 100%"
      >
        <el-table-column prop="orderNo" label="Order Detail" min-width="120">
          <template slot-scope="scope">
            <div>Order No.: {{ scope.row.orderNo }}</div>
            <div>Order date: {{ scope.row.date }}</div>
          </template>
        </el-table-column>
        <el-table-column label="Product Information" min-width="250">
          <template slot-scope="scope">
            <div class="product-flex">
              <el-image
                v-if="scope.row.imgUrl"
                :src="scope.row.imgUrl"
                :preview-src-list="scope.row.imageList"
                class="table-img"
              >
              </el-image>
              <img
                v-else
                src="../../assets/images/empty-img.jpg"
                alt="productImg"
                class="table-img"
              />
              <div>
                <div class="product-text">{{ scope.row.name }}</div>
                <div class="product-text" v-if="scope.row.code">
                  {{ scope.row.code }}
                </div>
                <div class="product-text" v-if="scope.row.brand">
                  {{ scope.row.brand }}
                </div>
                <div class="product-text bold">
                  ${{ $utils.keepTwoDecimalFull(scope.row.price) }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="img" label="Images" min-width="60" align="center">
          <template slot-scope="scope">
            <img :src="scope.row.img" alt="" class="table-img">
          </template>
        </el-table-column>
        <el-table-column prop="name" label="Product" min-width="140" align="center"></el-table-column>
        <el-table-column prop="price" label="Unit Price" min-width="100" align="center">
          <template slot-scope="scope">
            <span>${{ $utils.keepTwoDecimalFull(scope.row.price) }}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="quantity" label="Quantity" min-width="80">
          <template slot-scope="scope">
            <span>x{{ scope.row.quantity }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="total" label="Total">
          <template slot-scope="scope">
            <span
              >${{
                $utils.keepTwoDecimalFull(
                  $utils.accMul(scope.row.price, scope.row.quantity)
                )
              }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="amount" label="Amount" min-width="80">
          <template slot-scope="scope">
            <span>${{ $utils.keepTwoDecimalFull(scope.row.amount) }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <common-footer></common-footer>
  </div>
</template>

<script>
import shopHead from "../../components/shop/head";
import commonFooter from "../../components/common/footer";
import breadcrumb from "../../components/common/breadcrumb";
export default {
  components: {
    shopHead,
    commonFooter,
    breadcrumb,
  },
  data() {
    return {
      spanArr: [],
      orderList: [],
    };
  },
  mounted() {
    if (window.localStorage.getItem("orderData")) {
      this.orderList = JSON.parse(window.localStorage.getItem("orderData"));
    }
    this.getSpanArr();
  },
  methods: {
    /** 记录表格每行的rowspan值 */
    getSpanArr() {
      this.spanArr = [];
      let pos = 0;
      for (let i = 0; i < this.orderList.length; i++) {
        //第一行默认rowspan为1，
        if (i === 0) {
          this.spanArr.push(1);
        } else {
          //询价内容相同的行，需要合并
          if (this.orderList[i].orderNo === this.orderList[i - 1].orderNo) {
            this.spanArr[pos] += 1;
            this.spanArr.push(0);
          } else {
            this.spanArr.push(1);
            pos = i;
          }
        }
      }
    },
    /** 合并表格 */
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (column.property === "orderNo" || column.property === "amount") {
        return {
          rowspan: this.spanArr[rowIndex],
          colspan: this.spanArr[rowIndex] > 0 ? 1 : 0,
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.product-flex {
  display: flex;
  align-content: center;

  .product-text {
    line-height: 20px;
    font-size: 12px;
    text-align: left;

    &.bold {
      font-weight: bold;
      margin-top: 10px;
    }
  }
}

.table-box {
  margin: 30px auto 30px;
}
</style>
